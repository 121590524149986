export default {
  name: "chooseBtn",
  props: {
    title: {
      type: String
    }
  },
  methods: {
    emitAnswer() {
      this.$emit("emitAnswer", this.title);
    }
  }
};
