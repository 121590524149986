import { render, staticRenderFns } from "./chooseBtn.vue?vue&type=template&id=a7f079a6&scoped=true&"
import script from "./chooseBtn.js?vue&type=script&lang=js&"
export * from "./chooseBtn.js?vue&type=script&lang=js&"
import style0 from "./chooseBtn.scss?vue&type=style&index=0&id=a7f079a6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a7f079a6",
  null
  
)

export default component.exports