export default {
  name: "displayIcon",
  props: {
    name: {
      type: String
    },
    imgClasses: {
      type: String
    }
  },
  computed: {
    getPicture() {
      return require(`@/assets/${this.name}`);
    }
  }
};
