import { mapGetters } from "vuex";
import plainBtn from "@/components/buttons/plainBtn/plainBtn.vue";
import synthesisText from "@/components/synthesisText/synthesisText.vue";

export default {
  name: "synthesis",
  components: {
    plainBtn,
    synthesisText
  },
  computed: {
    ...mapGetters(["answers", "synthesisText", "availableResponses", "words"]),
    validateAnswers() {
      let responses = {};
      this.availableResponses.forEach(rep => {
        responses[rep] = [];
      });
      Object.values(this.words).forEach((word, index) => {
        responses[word["good-answer"]].push({
          name: word.name,
          valide: this.answers[index] === word["good-answer"]
        });
      });
      return responses;
    }
  },
  methods: {
    resetChapter() {
      this.$store.dispatch("resetFindAssociation");
      this.$router.push({
        name: "Home"
      });
    }
  }
};
