import arrowBack from "../icons/arrowBack";
import displayIcon from "@/components/displayIcon/displayIcon.vue";
export default {
  name: "appHeader",
  components: {
    arrowBack,
    displayIcon
  },
  props: {
    chapter: {
      type: String
    },
    chapterName: {
      type: String
    },
    icon: {
      type: String
    },
    instruction: {
      type: String
    },
    progress: {
      type: Number
    }
  },
  computed: {
    progressStatut() {
      return `${this.progress}%`;
    },
    bgImg() {
      return {
        backgroundImage: `url('${this.getPicture(this.icon)}')`
      }
    } 
  },
  methods: {
    getPicture(picture) {
      return require(`@/assets/${picture}`);
    },
    goBack() {
      window.history.back();
      this.$emit("goBack");
    }
  }
};
