import chooseBtn from "@/components/buttons/chooseBtn/chooseBtn.vue";
import synthesis from "@/components/synthesis/synthesis.vue";
import displayIcon from "@/components/displayIcon/displayIcon.vue";
export default {
  name: "findAssociation",
  components: {
    chooseBtn,
    synthesis,
    displayIcon
  },
  props: {
    availableResponses: {
      type: Array
    },
    actualWord: {
      type: String
    },
    actualIcon: {
      type: String
    },
    showSynthesis: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    listenAnswer(answer) {
      try {
        // store answer to answer array
        this.$store.dispatch("answer", answer);
      } catch (e) {
        console.error(e);
      }
      this.$emit("changeQuestion");
    }
  }
};
